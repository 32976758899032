import react, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";
import Player from '@vimeo/player';
import { useAuth } from "./Auth";

const WelcomeModalEl = styled.div`
  background: rgba(48, 52, 64, 0.94);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding-top: 48px;

  div.modal-container {
    max-width: 1004px;
    width: 100%;
    padding: 0 32px;
  }

  button.close-btn {
    background: transparent;
    border: none;
    display: block;
    margin-left: auto;
    position: relative;
    margin-bottom: 14px;

    @media (min-width: 1080px) {
      right: -31px;
    }

    img {
      width: 27px;
      height: 27px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  div.modal-content-container {
    div.modal-content {
      background: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 82px 30px 144px 30px;

      @media (max-width: 500px) {
        padding: 42px 30px 90px 30px;
      }

      img {
        max-width: 512px;
        width: 100%;
        height: auto;
        margin-bottom: 16px;
      }

      p {
        color: #0c66a5;
        font-size: 28px;
        line-height: 40px;
        letter-spacing: -0.7px;
        font-weight: 500;
        margin-bottom: 76px;
        text-align: center;

        @media (max-width: 500px) {
          margin-bottom: 40px;
        }
      }

      button.start-btn {
        background: #dc6126;
        border: solid 2px #dc6126;
        font-size: 19px;
        line-height: 30px;
        color: #fff;
        font-weight: 500;
        padding: 6px 30px;
        border-radius: 100px;
        transition: all 0.2s ease 0s;

        &:hover {
          cursor: pointer;
          background: transparent;
          color: #dc6126;
        }
      }
    }

    .modal-video {
    }
  }

  .video-embed-container {
    padding: 56.25% 0 0 0;
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

const WelcomeModal = () => {
  const logo = require("./assets/images/logo-modal.png");
  const iconClose = require("./assets/images/icn-close-white.png");

  const { setWelcomeModal, setWelcomeComplete } = useAuth();

  const [modalOpen, setModalOpen] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);

  const videoPlayer = useRef(null);

  useEffect(() => {
    if (videoPlayer.current) {
      const player = new Player(videoPlayer.current);
      console.log(player);
      player.on('ended', function() {
        // Set cookie so we know when welcome has been completed in the future.
        Cookies.set('welcome-complete', 'true', {expires: 3652});
        // Set welcome complete = true so we know not to show onboarding banner.
        setWelcomeComplete(true);
      })
    }
  }, [showVideo]);

  return (
    <>
      {modalOpen && (
        <WelcomeModalEl>
          <div className="modal-container">
            {/* Close Modal Button */}
            <button
              className="close-btn"
              onClick={() => {
                setModalOpen(false);
                setWelcomeModal(false);
              }}
            >
              <img src={iconClose} alt="Close welcome modal" />
            </button>

            {/* Modal Content */}
            <div className="modal-content-container">
              {!showVideo && (
                <div className="modal-content">
                  <img src={logo} alt="myCUES dashboard" />
                  <p>Your new online member experience.</p>
                  <button
                    className="start-btn"
                    onClick={() => {
                      setShowVideo(true);
                    }}
                  >
                    Start Now
                  </button>
                </div>
              )}

              {showVideo && (
                <div className="modal-video">
                  <div className="video-embed-container">
                    <iframe
                      src="https://player.vimeo.com/video/953949167?h=416f17e825&autoplay=1&title=0&byline=0&portrait=0"
                      frameborder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowfullscreen
                      ref={videoPlayer}
                    ></iframe>
                  </div>
                </div>
              )}
            </div>
          </div>
        </WelcomeModalEl>
      )}
    </>
  );
};

export default WelcomeModal;
