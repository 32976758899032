import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { useAuth } from "../../Auth";
import ReactGA from "react-ga4";
import axios from "axios";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

// import required modules
import { Grid, Keyboard, Pagination } from "swiper";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const EventsElement = styled.section`
  display: flex;
  justify-content: center;
  padding: 57px 20px 52px 20px;
  background: #f1f1f1;
  overflow-x: hidden;

  .cmp-events-container {
    max-width: 1280px;
    width: 100%;
  }

  .cmp-events-header {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    flex-wrap: wrap;

    @media (max-width: 815px) {
      flex-direction: column;
      align-items: flex-start;
    }

    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: -0.48px;
      color: #333333;
      margin-right: 40px;

      @media (max-width: 815px) {
        margin-right: unset;
        margin-bottom: 20px;
      }
    }

    ul.cmp-events-tabs {
      display: flex;

      @media (max-width: 815px) {
        order: 3;
        margin-bottom: 10px;
      }

      li {
        &:not(:last-child) {
          margin-right: 53px;

          @media (max-width: 860px) {
            margin-right: 32px;
          }
        }

        button {
          background: none;
          border: none;
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
          letter-spacing: -0.36px;
          color: #6a6d75;
          padding: 0;

          span {
            color: #dc6126;
          }

          &:hover {
            color: #303440;
            cursor: pointer;
          }

          &.active {
            color: #303440;
            position: relative;

            &::after {
              content: " ";
              position: absolute;
              height: 3px;
              left: 0;
              right: 0;
              bottom: -10px;
              background: #dc6126;
            }
          }
        }
      }
    }

    a {
      margin-left: auto;
      border-radius: 30px;
      background: #dc6126;
      border: 2px solid #dc6126;
      text-decoration: none;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0;
      padding: 4px 24px;

      @media (max-width: 815px) {
        order: 2;
        margin-left: unset;
        margin-bottom: 30px;
      }
    }
  }

  .cmp-events-feed {
    @media (max-width: 520px) {
      display: none;
    }

    .swiper {
      overflow: visible;
      padding-bottom: 50px;

      .swiper-pagination {
        bottom: 0px;

        .swiper-pagination-bullet {
          height: 15px;
          width: 15px;
          background: none;
          border: 2px solid #878990;
          opacity: 1;

          &:not(:last-of-type) {
            margin-right: 10px;
          }

          &.swiper-pagination-bullet-active {
            background: #dc6126;
            border: 2px solid #dc6126;
          }
        }
      }
    }

    .swiper-slide {
      width: 375px;
    }
  }

  .cmp-events-feed-mobile {
    @media (min-width: 521px) {
      display: none;
    }

    .cmp-events-card {
      margin-left: auto;
      margin-right: auto;
    }

    .swiper {
      padding-bottom: 50px;

      .swiper-pagination {
        bottom: 0px;

        .swiper-pagination-bullet {
          height: 15px;
          width: 15px;
          background: none;
          border: 2px solid #878990;
          opacity: 1;

          &:not(:last-of-type) {
            margin-right: 10px;
          }

          &.swiper-pagination-bullet-active {
            background: #dc6126;
            border: 2px solid #dc6126;
          }
        }
      }
    }
  }

  .cmp-events-card {
    background-size: cover;
    background-color: #303440;
    width: 375px;
    border-radius: 12px;
    height: 195px;
    position: relative;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
   
    
    &::before {
      border-radius: 12px;
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgb(36, 39, 49);
      background: linear-gradient(
        90deg,
        rgba(36, 39, 49, 0.9) 0%,
        rgb(130 130 133 / 40%) 100%
      );
      opacity: 0.9;
      z-index: 1;
    }

    .cmp-events-card-header,
    .cmp-events-card-footer {
      z-index: 2;
      position: relative;
    }

    .cmp-events-card-header {
      .cmp-event-card-date,
      .cmp-event-card-location {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0;
        color: #fff;
      }

      .cmp-event-card-title {
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 0;
        font-weight: 500;
        color: #fff;
        margin: 3px 0;
      }
      a.cmp-event-card-button {
        font: Roboto;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        padding: 8px;
        border-radius: 6px;
        background-color: #DC6126;
        margin-bottom: 14px;
        width:90px;
        text-transform: uppercase;
        text-align:center!important;
        display:block!important;
        &:hover {
          background: transparent;
          color: #fff;
        }

        &.register {
          background: #dc6126;
          color: #fff;
          display: flex;
          align-items: center;
          border: 2px solid #dc6126;

          img {
            width: 16px;
            height: 18px;
            margin-right: 6px;
          }
        }
      }
    }

    .cmp-events-card-footer {
      display: flex;
      align-items: center;

      .cmp-event-card-type-icon {
        margin-right: 20px;

        img {
          height: 22px;
          width: auto;
        }
      }

      a.cmp-event-card-button {
        background: #ffffff;
        border: 2px solid #fff;
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
        text-decoration: none;
        color: #303440;
        border-radius: 30px;
        padding: 3px 21px;

        &:hover {
          background: transparent;
          color: #fff;
        }

        &.register {
          background: #dc6126;
          color: #fff;
          display: flex;
          align-items: center;
          border: 2px solid #dc6126;

          img {
            width: 16px;
            height: 18px;
            margin-right: 6px;
          }
        }
      }

      a.cmp-event-card-more-info {
        margin-left: auto;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        text-decoration: none;
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        font-weight: 900;

        img {
          height: 16px;
          width: 16px;
          margin-left: 10px;
        }
      }
    }
  }

  .cmp-event-feed-empty {
    padding: 40px 0 20px 0;
  }

  .cmp-debug-btn {
    border-radius: 30px;
    background: #dc6126;
    border: 2px solid #dc6126;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
    padding: 4px 24px;
    margin-top: 10px;
  }

  .loading-container {
    display: flex;

    .loading-card {
      height: 240px;
      width: 100%;
      background: #fff;
      border-radius: 7px;
      padding: 26px 24px 21px;
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-right: 20px;
      }

      .loading-card-footer {
        margin-top: auto;
        display: flex;

        div {
          &:nth-child(1) {
            width: 12%;
            margin-right: 26px;
          }

          &:nth-child(2) {
            width: 35%;
          }
        }
      }
    }
  }
`;

const Events = () => {
  const comp = require("../../assets/images/icn-comp.png");
  const pin = require("../../assets/images/icn-pin.png");
  const arrow = require("../../assets/images/icn-arrow-r.png");
  const check = require("../../assets/images/icn-check-w.png");

  const [activeTab, setActiveTab] = useState("membership");
  const [loading, setLoading] = useState(true);
  const [eventData, setEventData] = useState(null);
  const [events, setEvents] = useState(null);

  const { cmpData, environment, cuesURL, cmanURL } = useAuth();

  function handleGAEvent(eventTitle, value) {
    if (cmpData) {
      if (window.location.host == "mycues.cues.org") {
        ReactGA.event(eventTitle, {
          value,
          user_role: cmpData.account_info.acgiRole,
          user_memberlevel: cmpData.account_info.membershipLevel,
          user_company: cmpData.account_info.employer,
        });
      }
    }
  }

  useEffect(() => {
    let eventsApiUrl;

    if (environment == "local") {
      setEventData({
        events: {
          withMembership: [
            {
              title:
                "Virtual Classroom: Becoming a ShiftThinker™:  Strategies for Continual Growth and Success! (Part 2)",
              image:
                "https://cues.lndo.site/sites/default/files/2022-06/22_Aug16-VC_Event-Image_1280x550%20%281%29.jpg",
              startDate: "2022-09-14",
              endDate: "2022-09-14",
              date: "September 14, 2022",
              eventType: "Online Event",
              eventLocation: null,
              registrationLink:
                "https://online.cues.org/cuesssa/ssaapexmnucall?p_app_id=EVTSSA&p_page_id=4010&p_itemnames=P0_EVENT_ID&p_itemvalues=2274&p_clearcache=0,4010",
              eventDetailsLink:
                "https://cues.lndo.site/professional-development-and-events/virtual-classroom-becoming-shiftthinkertm-strategies-0",
              eventRoles: [],
              destinationUrl:  "https://cues.lndo.site/professional-development-and-events/virtual-classroom-becoming-shiftthinkertm-strategies-0"
            },
            {
              title:
                "Webinar: What's In It For Me: The Ultimate Guide to CUES Membership",
              image:
                "https://cues.lndo.site/sites/default/files/2022-07/webinars-1280x550_1.jpg",
              startDate: "2022-09-29",
              endDate: "2022-09-29",
              date: "September 29, 2022",
              eventType: "Online Event",
              eventLocation: null,
              registrationLink:
                "https://online.cues.org/cuesssa/ssaapexmnucall?p_app_id=EVTSSA&p_page_id=4010&p_itemnames=P0_EVENT_ID&p_itemvalues=2287&p_clearcache=0,4010",
              eventDetailsLink:
                "https://cues.lndo.site/professional-development-and-events/webinar-whats-it-me-ultimate-guide-cues-membership",
              eventRoles: [],
              destinationUrl: null
            },
            {
              title:
                "Webinar: What's In It For Me: The Ultimate Guide to CUES Membership",
              image:
                "https://cues.lndo.site/sites/default/files/2022-07/webinars-1280x550_1.jpg",
              startDate: "2022-11-03",
              endDate: "2022-11-03",
              date: "November 3, 2022",
              eventType: "Online Event",
              eventLocation: null,
              registrationLink:
                "https://online.cues.org/cuesssa/ssaapexmnucall?p_app_id=EVTSSA&p_page_id=4010&p_itemnames=P0_EVENT_ID&p_itemvalues=2288&p_clearcache=0,4010",
              eventDetailsLink:
                "https://cues.lndo.site/professional-development-and-events/webinar-whats-it-me-ultimate-guide-cues-membership-0",
              eventRoles: [],
              destinationUrl: null
            },
            {
              title:
                "Webinar: What's In It For Me: The Ultimate Guide to CUES Membership",
              image:
                "https://cues.lndo.site/sites/default/files/2022-07/webinars-1280x550_1.jpg",
              startDate: "2023-01-26",
              endDate: "2023-01-26",
              date: "January 26, 2023",
              eventType: "Online Event",
              eventLocation: null,
              registrationLink:
                "https://online.cues.org/cuesssa/ssaapexmnucall?p_app_id=EVTSSA&p_page_id=4010&p_itemnames=P0_EVENT_ID&p_itemvalues=2289&p_clearcache=0,4010",
              eventDetailsLink:
                "https://cues.lndo.site/professional-development-and-events/webinar-whats-it-me-ultimate-guide-cues-membership-1",
              eventRoles: [],
              destinationUrl: null
            },
            {
              title:
                "Webinar: What's In It For Me: The Ultimate Guide to CUES Membership",
              image:
                "https://cues.lndo.site/sites/default/files/2022-07/webinars-1280x550_1.jpg",
              startDate: "2023-03-02",
              endDate: "2023-03-02",
              date: "March 2, 2023",
              eventType: "Online Event",
              eventLocation: null,
              registrationLink:
                "https://online.cues.org/cuesssa/ssaapexmnucall?p_app_id=EVTSSA&p_page_id=4010&p_itemnames=P0_EVENT_ID&p_itemvalues=2290&p_clearcache=0,4010",
              eventDetailsLink:
                "https://cues.lndo.site/professional-development-and-events/webinar-whats-it-me-ultimate-guide-cues-membership-2",
              eventRoles: [],
              destinationUrl: null
            },
          ],
          purchase: [],
          registered: [
            // {
            //   title: "2023 CUES Symposium",
            //   image:
            //     "https://cues.lndo.site/sites/default/files/2022-01/22_Symp_Hero_1280x550.jpg",
            //   startDate: "02/06/2023",
            //   endDate: "02/10/2023",
            //   date: "February 6-10, 2023",
            //   eventType: "In-Person Event",
            //   eventLink:
            //     "https://online.cues.org/cuesssa/ssaapexmnucall?p_app_id=EVTSSA&p_page_id=4010&p_itemnames=P0_EVENT_ID&p_itemvalues=2247&p_clearcache=0,4010",
            //   eventDetailsLink: "/symposium",
            //   eventDestinationUrl:null
            // },
            // {
            //   title: "2023 CUES Symposium 1",
            //   image:
            //     "https://cues.lndo.site/sites/default/files/2022-01/22_Symp_Hero_1280x550.jpg",
            //   startDate: "02/06/2023",
            //   endDate: "02/10/2023",
            //   date: "February 6-10, 2023",
            //   eventType: "In-Person Event",
            //   eventLink:
            //     "https://online.cues.org/cuesssa/ssaapexmnucall?p_app_id=EVTSSA&p_page_id=4010&p_itemnames=P0_EVENT_ID&p_itemvalues=2247&p_clearcache=0,4010",
            //   eventDetailsLink: "/symposium",
            //   eventDestinationUrl:  "https://cues.lndo.site/professional-development-and-events/virtual-classroom-becoming-shiftthinkertm-strategies-0"
              
            // },
          ],
        },
      });

      setLoading(false);
    } else {
      
      eventsApiUrl = cuesURL + "/cmp/events"

      axios
        .get(eventsApiUrl)
        .then(function (response) {
          setEventData(response.data);
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
        });
    }
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      switch (activeTab) {
        case "membership":
          setEvents(eventData.events.withMembership);
          break;
        case "purchase":
          setEvents(eventData.events.purchase);
          break;
        case "registered":
          setEvents(eventData.events.registered);
          break;
        default:
          console.log("I don't know what events to show...");
      }
    }
  }, [loading, activeTab]);

  return (
    <>
      {loading && (
        <EventsElement>
          <div className="cmp-events-container">
            {/* Header */}
            <div className="cmp-events-header">
              <h2>Events</h2>
            </div>

            <div className="loading-container">
              <div className="loading-card">
                <div className="loading-card-header">
                  <Skeleton count={2} height={24} />
                </div>

                <div className="loading-card-footer">
                  <div>
                    <Skeleton count={1} height={24} />
                  </div>
                  <div>
                    <Skeleton count={1} height={24} />
                  </div>
                </div>
              </div>

              <div className="loading-card">
                <div className="loading-card-header">
                  <Skeleton count={2} height={24} />
                </div>

                <div className="loading-card-footer">
                  <div>
                    <Skeleton count={1} height={24} />
                  </div>
                  <div>
                    <Skeleton count={1} height={24} />
                  </div>
                </div>
              </div>

              <div className="loading-card">
                <div className="loading-card-header">
                  <Skeleton count={2} height={24} />
                </div>

                <div className="loading-card-footer">
                  <div>
                    <Skeleton count={1} height={24} />
                  </div>
                  <div>
                    <Skeleton count={1} height={24} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </EventsElement>
      )}

      {!loading && eventData && events && (
        <EventsElement>
          <div className="cmp-events-container">
            {/* Header */}
            <div className="cmp-events-header">
              <h2>Events</h2>
              <ul className="cmp-events-tabs">
                <li>
                  <button
                    className={activeTab == "membership" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("membership");
                    }}
                  >
                    Included with Membership
                  </button>
                </li>
                <li>
                  <button
                    className={activeTab == "purchase" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("purchase");
                    }}
                  >
                    For Purchase
                  </button>
                </li>
                <li>
                  <button
                    className={activeTab == "registered" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("registered");
                    }}
                  >
                    {/* Display the number of events the user has registered for. */}
                    Registered{" "}
                    <span>({eventData.events.registered.length})</span>
                  </button>
                </li>
              </ul>

              <a
                href={
                  "https://www.cues.org/professional-development-and-events"
                }
                target="_blank"
                className="cmp-events-view-all-link"
                onClick={() => {
                  handleGAEvent("events_viewall");
                }}
              >
                View All Events
              </a>
            </div>

            {/* Feed */}
            <div className="cmp-events-feed">
              {eventData && events && Object.values(events).length > 0 ? (
                <Swiper
                  simulateTouch={true}
                  slidesPerView={"auto"}
                  slidesPerGroup={3}
                  spaceBetween={30}
                  keyboard={{
                    enabled: true,
                  }}
                  pagination={{
                   type:'none'
                  }}
                  modules={[Pagination, Keyboard]}
                  className="mySwiper"
                >
                  <>
                    {Object.values(events).map((event) => (
                      <SwiperSlide>
                        <div
                          className="cmp-events-card"
                          data-event-id={event.eventId}
                          data-event-node-id={event.eventNodeId}
                          style={{
                            backgroundImage: event.image
                              ? `url("https://www.cues.org${event.image}")`
                              : `none`,
                          }}
                        >
                         
                          <div className="cmp-events-card-header">
                          {(activeTab == "registered" && (event.eventDestinationUrl === null ||
                                event.eventDestinationUrl === undefined))&& (
                              <a
                                className={`cmp-event-card-button register${
                                  event.eventDestinationUrl ? "" : " no-link"
                                }`}
                                href={event.eventDestinationUrl}
                                target={
                                  event.eventDestinationUrl ? "_blank" : ""
                                }
                              >
                              {event.eventDestinationUrl === null ||
                                event.eventDestinationUrl === undefined
                                  ? " Registered"
                                  : " Access Now"}
                              </a>
                            )}
                            <p className="cmp-event-card-date">{event.date}</p>
                            <p className="cmp-event-card-title">
                              {event.title}
                            </p>

                            {event.eventLocation && (
                              <p className="cmp-event-card-location">
                                {event.eventLocation}
                              </p>
                            )}
                          </div>

                          <div className="cmp-events-card-footer">
                            <div className="cmp-event-card-type-icon">
                              {event.eventType !== "Online Event" ? (
                                <img src={pin} alt="Online Event" />
                              ) : (
                                <img src={comp} alt="In-Person Event" />
                              )}
                            </div>

                            {/* Show registered button for registered events, or show register button for non-registered events */}

                            {event.registrationLink && (
                              <a
                                href={event.registrationLink}
                                className="cmp-event-card-button cmp-event-card-button--register"
                                onClick={() => {
                                  handleGAEvent(
                                    "events_register_click",
                                    event.registrationLink
                                  );
                                }}
                              >
                                Register
                              </a>
                            )}

                            {(activeTab == "registered" && event.eventDestinationUrl !== null) && (
                              <a
                                className={`cmp-event-card-button register${
                                  event.eventDestinationUrl ? "" : " no-link"
                                }`}
                                href={event.eventDestinationUrl}
                                target={
                                  event.eventDestinationUrl ? "_blank" : ""
                                }
                              >
                                {(event.eventDestinationUrl === null ||
                                  event.eventDestinationUrl === undefined) && (
                                  <img src={check} />
                                )}{" "}
                                {event.eventDestinationUrl === null ||
                                event.eventDestinationUrl === undefined
                                  ? "Registered"
                                  : " Access Now"}
                              </a>
                            )}

                            {event.eventDetailsLink && (
                              <>
                                <a
                                  href={
                                    activeTab == "registered"
                                      ? cuesURL + event.eventDetailsLink
                                      : event.eventDetailsLink
                                  }
                                  target="_blank"
                                  className="cmp-event-card-more-info"
                                  onClick={() => {
                                    if (activeTab == "registered") {
                                      handleGAEvent(
                                        "events_currently_registered",
                                        event.eventDetailsLink
                                      );
                                    } else {
                                      handleGAEvent(
                                        "events_moreinfo",
                                        event.eventDetailsLink
                                      );
                                    }
                                  }}
                                >
                                  More Info <img src={arrow} />
                                </a>
                              </>
                            )}
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </>
                </Swiper>
              ) : (
                <div className="cmp-event-feed-empty">
                  {activeTab == "registered" && (
                     <>
                    <div className="cmp-events-card empty">
                      <div className="cmp-events-card-header">
                        <div className="cmp-event-card-title">
                          You're not currently enrolled in any events. Don't miss out! Take a look at all of our upcoming events.
                        </div>
                        </div>
                        <div className="cmp-events-card-footer">
                        <a
                                href={
                                  "https://www.cues.org/professional-development-and-events"
                                }
                                target="_blank"
                                className="cmp-event-card-button cmp-event-card-button--register"
                                onClick={() => {
                                  handleGAEvent("events_viewall");
                                }}
                              >
                                Explore Now
                              </a>
                        {/* )} */}
                        </div>
                      
                      </div>
                   </>
                    // <>
                    //   <p>
                    //     <em>
                    //       Looks like you don't have any events planned; they
                    //       will appear here as you register for them.
                    //     </em>
                    //   </p>
                    // </>
                  )}

                  {activeTab == "membership" && (
                    <>
                      <p>
                        <em>
                          Looks like there are no membership events for you
                          currently, but be sure to check back later—we're
                          always adding new ones!
                        </em>
                      </p>
                    </>
                  )}

                  {activeTab == "purchase" && (
                    <>
                     <div className="cmp-events-card empty">
                      <div className="cmp-events-card-header">
                        <div className="cmp-event-card-title">
                        Looks like there are no recommended events for you
                          currently, but be sure to check back later–we’re
                          always adding new ones!
                        </div>
                        </div>
                        <div className="cmp-events-card-footer">
                        {/* <a
                                href={
                                  "https://www.cues.org/professional-development-and-events"
                                }
                                target="_blank"
                                className="cmp-event-card-button cmp-event-card-button--register"
                                onClick={() => {
                                  handleGAEvent("events_viewall");
                                }}
                              >
                                Explore Events
                              </a> */}
                        {/* )} */}
                        </div>
                      
                      </div>
                      {/* <p>
                        <em>
                          Looks like there are no recommended events for you
                          currently, but be sure to check back later–we’re
                          always adding new ones!
                        </em>
                      </p> */}
                    </>
                  )}
                </div>
              )}
            </div>

            {/* Mobile Feed */}
            <div className="cmp-events-feed-mobile">
              {eventData && events && Object.values(events).length > 0 ? (
                <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Grid, Pagination]}
                  className="mySwiper"
                >
                  <>
                    {Object.values(events).map((event) => (
                      <SwiperSlide>
                        <div
                          className="cmp-events-card"
                          data-event-id={event.eventId}
                          data-event-node-id={event.eventNodeId}
                          style={{
                            backgroundImage: event.image
                              ? `url("${event.image}")`
                              : `none`,
                          }}
                        >
                          <div className="cmp-events-card-header">
                            <p className="cmp-event-card-date">{event.date}</p>
                            <p className="cmp-event-card-title">
                              {event.title}
                            </p>

                            {event.eventLocation && (
                              <p className="cmp-event-card-location">
                                {event.eventLocation}
                              </p>
                            )}
                          </div>

                          <div className="cmp-events-card-footer">
                            <div className="cmp-event-card-type-icon">
                              {event.eventType !== "Online Event" ? (
                                <img src={pin} alt="Online Event" />
                              ) : (
                                <img src={comp} alt="In-Person Event" />
                              )}
                            </div>

                            {/* Show registered button for registered events, or show register button for non-registered events */}

                            {event.registrationLink && (
                              <a
                                href={event.registrationLink}
                                className="cmp-event-card-button"
                                onClick={() => {
                                  handleGAEvent(
                                    "events_register_click",
                                    event.registrationLink
                                  );
                                }}
                              >
                                Register
                              </a>
                            )}

                            {activeTab == "registered" && (
                              <a
                                className={`cmp-event-card-button register${
                                  event.eventDestinationUrl ? "" : " no-link"
                                }`}
                                href={event.eventDestinationUrl}
                                target={
                                  event.eventDestinationUrl ? "_blank" : ""
                                }
                              >
                                {(event.eventDestinationUrl === null ||
                                  event.eventDestinationUrl === undefined) && (
                                  <img src={check} />
                                )}
                                {event.eventDestinationUrl === null ||
                                event.eventDestinationUrl === undefined
                                  ? "Registered"
                                  : " Access Now"}
                              </a>
                            )}

                            {event.eventDetailsLink && (
                              <a
                                href={event.eventDetailsLink}
                                target="_blank"
                                className="cmp-event-card-more-info"
                                onClick={() => {
                                  if (activeTab == "registered") {
                                    handleGAEvent(
                                      "events_currently_registered",
                                      event.eventDetailsLink
                                    );
                                  } else {
                                    handleGAEvent(
                                      "events_moreinfo",
                                      event.eventDetailsLink
                                    );
                                  }
                                }}
                              >
                                More Info <img src={arrow} />
                              </a>
                            )}
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </>
                </Swiper>
              ) : (
                <div className="cmp-event-feed-empty">
                  {activeTab == "registered" && (
                    <>
                      <p>
                        <em>
                          Looks like you don't have any events planned; they
                          will appear here as you register for them.
                        </em>
                      </p>
                    </>
                  )}

                  {activeTab == "membership" && (
                    <>
                      <p>
                        <em>
                          Looks like there are no membership events for you
                          currently, but be sure to check back later—we're
                          always adding new ones!
                        </em>
                      </p>
                    </>
                  )}

                  {activeTab == "purchase" && (
                    <>
                      <p>
                        <em>
                          Looks like there are no recommended events for you
                          currently, but be sure to check back later–we’re
                          always adding new ones!
                        </em>
                      </p>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </EventsElement>
      )}
    </>
  );
};

export default Events;
