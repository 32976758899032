import React, { useState, useEffect } from "react";
import iconScrolldown from './assets/images/icon-scroll-down@2x.png'
import iconClose from './assets/images/icon-scroll-close@2x.png'
const Pager = () => {
    const [scrollAmount, setScrollAmount] = useState(0);
    const [isVisible, setIsVisible] = useState(true);
    const [savedScrollPosition, setSavedScrollPosition] = useState(0);

    const scrollToNextPage = () => {
        // Calculate the maximum scroll height
        const maxScrollHeight = document.body.scrollHeight;
        const pageHeight = window.innerHeight - 50;
        const nextPageScroll = scrollAmount + pageHeight;
    
        if (nextPageScroll <= maxScrollHeight) {
            // Save the current scroll position
            setSavedScrollPosition(window.scrollY);
    
            window.scrollTo({
                top: nextPageScroll,
                behavior: 'smooth',
            });
            setScrollAmount(nextPageScroll);
        } 
    };

    
    const isMobile = window.innerWidth <= 768; // You can adjust the breakpoint as needed

    const scrollBarStyles = {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        padding: isMobile?'14px 0px':'24px 30px',
        backgroundColor: '#DC6126',
        boxShadow: '0 0 70px rgba(0,0,0,.5)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontFamily: 'Roboto-Bold',
        fontSize: isMobile ? '16px' : '22px', // Responsive font size
        color: '#fff',
        zIndex: 9999
    };
   const contentStyle={
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
   }
    const buttonStyles = {
        background: 'none',
        border: 'none',
        outline: 'none',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        color: 'inherit',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        cursor: 'pointer',
        flex: isMobile?1:'none',
        justifyContent:'center',
      
    };
  
    const imgStyles = {
      height: '26px',
      alignSelf: 'center',
      marginRight: '10px',
    };
    const buttonTextStyles = {
        flex: 1, // Fill available space
      };
    const buttonText = isMobile ? 'Scroll to View Your Benefits!' : 'Scroll to Discover Your Personalized Learning, Events and More!';
  
    useEffect(() => {
      
        // Function to handle scroll events
        const handleScroll = () => {
            // Calculate how much the user has scrolled from the top
            const currentScroll = window.scrollY;
            
            // Calculate the maximum scroll height
            const maxScrollHeight = document.body.scrollHeight;
            const pageHeight = window.innerHeight;
            const bottomScrollPosition = maxScrollHeight - pageHeight;
            const nextPageScroll = scrollAmount + pageHeight;
            //setScrollAmount(nextPageScroll);
            // You can adjust this threshold value as needed
            const scrollThreshold = 200; // Example threshold value

            // If the user has scrolled beyond the threshold or reached the bottom, hide the Pager component
            if (currentScroll >= bottomScrollPosition) {
                setIsVisible(false);
            } else {
                //setIsVisible(true);
            }
        };

        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array to run this effect only once when the component mounts


    return (
        <>{isVisible && (
            <div className="scroll-bar" style={scrollBarStyles}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flex: 1
                }}>
                  <button style={buttonStyles} onClick={scrollToNextPage}>
                    <img src={iconScrolldown} style={{ height: 26, alignSelf: 'center', marginRight: 10 }} />
                    {buttonText}
                  </button>
                </div>
                <div>
                  <button style={{
                    background: 'none',
                    border: 'none',
                    outline: 'none',
                    fontFamily: 'inherit',
                    fontSize: 'inherit',
                    color: 'inherit',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }} onClick={() => setIsVisible(false)}>
                    <img src={iconClose} style={{ height: 26, alignSelf: 'center', marginRight: 10 }} />
                  </button>
                </div>
              </div>
        )}</>
       
    );
};

export default Pager;