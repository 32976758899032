import React, { useState, useRef } from "react";
import styled from "styled-components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { borderRadius } from "@mui/system";
import Tooltip from "@mui/material/Tooltip";
import { useAuth } from "../../Auth";
import ReactGA from "react-ga4";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

// import required modules
import { Grid, Keyboard,Pagination } from "swiper";

const ArticlesElement = styled.section`
  display: flex;
  justify-content: center;
  background: #f1f1f1;
  padding: 52px 20px 70px 20px;

  .cmp-articles-container {
    max-width: 1280px;
    width: 100%;
  }

  .cmp-articles-header {
    margin-bottom: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 865px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media (max-width: 630px) {
      align-items: center;
      margin-bottom: 50px;
    }

    .cmp-articles-branding {
      @media (max-width: 630px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      img {
        max-width: 426px;
        width: 100%;
        height: auto;
        margin-bottom: 6px;

        @media (max-width: 630px) {
          margin-bottom: 10px;
        }
      }

      p {
        font-size: 14px;
        line-height: 12px;
        letter-spacing: 0.42px;
        font-weight: 500;
        color: #303440;
        text-transform: uppercase;

        @media (max-width: 630px) {
          text-align: center;
          line-height: 18px;
        }
      }
    }

    a.cmp-articles-button {
      @media (max-width: 865px) {
        margin-top: 20px;
      }

      border-radius: 30px;
      background: #dc6126;
      border: 2px solid #dc6126;
      text-decoration: none;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0;
      padding: 4px 24px;
    }
  }

  .cmp-articles-feed {
    overflow-x: scroll;
    margin: 0 calc(50% - 50vw);
    padding-left: calc((100vw - 1280px) / 2);
    padding-right: calc((100vw - 1280px) / 2);
    padding-bottom: 10px;
    .swiper {
      overflow: visible;
      padding-bottom: 50px;

      .swiper-pagination {
        bottom: 0px;

        .swiper-pagination-bullet {
          height: 15px;
          width: 15px;
          background: none;
          border: 2px solid #878990;
          opacity: 1;

          &:not(:last-of-type) {
            margin-right: 10px;
          }

          &.swiper-pagination-bullet-active {
            background: #dc6126;
            border: 2px solid #dc6126;
          }
        }
      }
    }
    .swiper-slide {
      width: 375px;
    }
    @media (max-width: 520px) {
      display: none;
    }

    /* Hide scroll bar */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    ul {
      display: flex;

      @media (max-width: 1300px) {
        padding-left: 20px;
      }

      li {
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }

  .cmp-articles-feed-mobile {
    @media (min-width: 521px) {
      display: none;
    }

    .cmp-article-card {
      margin-right: auto;
      margin-left: auto;
    }

    .swiper {
      padding-bottom: 50px;

      .swiper-pagination {
        bottom: 0px;

        .swiper-pagination-bullet {
          height: 15px;
          width: 15px;
          background: none;
          border: 2px solid #878990;
          opacity: 1;

          &:not(:last-of-type) {
            margin-right: 10px;
          }

          &.swiper-pagination-bullet-active {
            background: #dc6126;
            border: 2px solid #dc6126;
          }
        }
      }
    }
  }

  .cmp-article-card {
    width: 375px;
    height: 425px;
    border-radius: 8px;
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 6px #00000029;

    .cmp-article-card-header {
      height: 225px;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 8px 8px 0 0;
      position: relative;

      .cmp-article-card-read-time {
        position: absolute;
        bottom: 14px;
        left: 10px;
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        padding: 4px 6px;
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.65);
      }
    }

    .cmp-article-card-content {
      padding: 16px 20px 38px 20px;
      flex: 1;
      display: flex;
      flex-direction: column;

      .cmp-article-card-type {
        margin-bottom: 15px;

        p {
          font-size: 16px;
          line-height: 19px;
          font-weight: 500;
          text-transform: uppercase;
          color: #dc6126;
          display: flex;
          align-items: center;

          img {
            height: 16px;
            width: auto;
            margin-right: 8px;
          }
        }
      }

      .cmp-article-card-date,
      .cmp-article-card-author {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        color: #303440;
      }

      .cmp-article-card-title {
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        color: #000000;
        margin-top: 6px;
        text-decoration: none;
      }

      .cmp-article-card-author {
        margin-top: auto;
      }
    }
  }
`;

const CUManagementSection = () => {
  const videoIcon = require("../../assets/images/icn-video-o.png");
  const articleIcon = require("../../assets/images/icn-article-o.png");
  const blogIcon = require("../../assets/images/icn-blog-o.png");
  const logo = require("../../assets/images/logo-cumanagement-cues.png");
  const arrowIcon = require("../../assets/images/icn-arrow-r.png");

  const { cmpData, cmanURL, cuesURL } = useAuth();
  const { articles } = cmpData;

  function handleGAEvent(eventTitle, value) {
    if (cmpData) {
      if (window.location.host == "mycues.cues.org") {
        ReactGA.event(eventTitle, {
          value,
          user_role: cmpData.account_info.acgiRole,
          user_memberlevel: cmpData.account_info.membershipLevel,
          user_company: cmpData.account_info.employer,
        });
      }
    }
  }

  return (
    <ArticlesElement>
      <div className="cmp-articles-container">
        <div className="cmp-articles-header">
          <div className="cmp-articles-branding">
            <img src={logo} alt="CUManagement Logo" />
            <p>
              PROVIDING DAILY INSIGHTS FOR TODAY'S PROGRESSIVE CREDIT UNION
              LEADERS.
            </p>
          </div>

          <a
            href={cuesURL + "/cumanagement-external"}
            target="_blank"
            className="cmp-articles-button"
            onClick={() => {
              handleGAEvent("cumanagment_visit");
            }}
          >
            Take Me To My Digital Magazine
          </a>
        </div>

        <div className="cmp-articles-feed">
        <Swiper
            simulateTouch={true}
            slidesPerView={"auto"}
            slidesPerGroup={3}
            spaceBetween={30}
            keyboard={{
              enabled: true,
            }}
            pagination={{
             type:'none'
            }}
            modules={[Pagination, Keyboard]}
            className="mySwiper"
          >
          <>
            {articles.map((article) => (
          <SwiperSlide>
          <div
              className="cmp-article-card"
              data-node-id={article.id}>
            <div
              className="cmp-article-card-header"
              style={{
                backgroundImage: article.image
                  ? `url("${article.image}")`
                  : "none",
              }}
            >
              {article.length && (
                <p className="cmp-article-card-read-time">
                  {article.length}
                </p>
              )}
            </div>

            <div className="cmp-article-card-content">
              {article.type && (
                <div className="cmp-article-card-type">
                  {/* Article */}
                  {article.type == "Article" && (
                    <p>
                      <img src={articleIcon} alt="Article" /> Article
                    </p>
                  )}

                  {/* Blog */}
                  {article.type == "Blog" && (
                    <p>
                      <img src={blogIcon} alt="Blog" /> Blog
                    </p>
                  )}

                  {/* Video */}
                  {article.type == "Video" && (
                    <p>
                      <img src={videoIcon} alt="Video" /> Video
                    </p>
                  )}
                </div>
              )}

              {article.date && (
                <p className="cmp-article-card-date">{article.date}</p>
              )}

              <a
                href={article.url}
                target="_blank"
                className="cmp-article-card-title"
                onClick={() => {
                  handleGAEvent("cumanagement_access", article.url);
                }}
              >
                {article.title}
              </a>

              {article.author && (
                <p className="cmp-article-card-author">
                  by {article.author}
                </p>
              )}
            </div>
          </div>
        </SwiperSlide>
                
            
            ))}
          </>
          </Swiper>
        </div>

        <div className="cmp-articles-feed-mobile">
          <Swiper
            simulateTouch={true}
            slidesPerView={"auto"}
            slidesPerGroup={3}
            spaceBetween={30}
            keyboard={{
              enabled: true,
            }}
            pagination={{
              clickable: true,
              
            }}
            modules={[Pagination, Keyboard]}
            className="mySwiper"
          >
            <>
              {articles.map((article) => (
                <SwiperSlide>
                  <div
                      className="cmp-article-card"
                      data-node-id={article.id}>
                    <div
                      className="cmp-article-card-header"
                      style={{
                        backgroundImage: article.image
                          ? `url("${article.image}")`
                          : "none",
                      }}
                    >
                      {article.length && (
                        <p className="cmp-article-card-read-time">
                          {article.length}
                        </p>
                      )}
                    </div>

                    <div className="cmp-article-card-content">
                      {article.type && (
                        <div className="cmp-article-card-type">
                          {/* Article */}
                          {article.type == "Article" && (
                            <p>
                              <img src={articleIcon} alt="Article" /> Article
                            </p>
                          )}

                          {/* Blog */}
                          {article.type == "Blog" && (
                            <p>
                              <img src={blogIcon} alt="Blog" /> Blog
                            </p>
                          )}

                          {/* Video */}
                          {article.type == "Video" && (
                            <p>
                              <img src={videoIcon} alt="Video" /> Video
                            </p>
                          )}
                        </div>
                      )}

                      {article.date && (
                        <p className="cmp-article-card-date">{article.date}</p>
                      )}

                      <a
                        href={article.url}
                        target="_blank"
                        className="cmp-article-card-title"
                        onClick={() => {
                          handleGAEvent("cumanagement_access", article.url);
                        }}
                      >
                        {article.title}
                      </a>

                      {article.author && (
                        <p className="cmp-article-card-author">
                          by {article.author}
                        </p>
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </>
          </Swiper>
        </div>
      </div>
    </ArticlesElement>
  );
};

export default CUManagementSection;
